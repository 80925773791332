import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete('/api/kualifikasi-jabatan-struktural/' + payload)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch('/api/kualifikasi-jabatan-struktural/' + payload.id, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/kualifikasi-jabatan-struktural', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get('/api/kualifikasi-jabatan-struktural?page=' + payload.page + '&id_opd=' + payload.data.id_opd + '&id_unit_kerja=' + payload.data.id_unit_kerja)
        return data
    },
    async autocomplete_opd(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-kualifikasi-jabatan-struktural/autocomplete_opd?search=' + payload)
            return data
        } catch (error) {
            return []
        }
    },
    async autocomplete_opd_unit_kerja(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-kualifikasi-jabatan-struktural/autocomplete_opd_unit_kerja?search=' + payload.search + '&id_opd=' + payload.id_opd)
            return data
        } catch (error) {
            return []
        }
    },
    async autocomplete_eselon(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-kualifikasi-jabatan-struktural/autocomplete_eselon?search=' + payload.search)
            return data
        } catch (error) {
            return []
        }
    },
    async autocomplete_tingkat_pendidikan(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-kualifikasi-jabatan-struktural/autocomplete_tingkat_pendidikan?search=' + payload.search)
            return data
        } catch (error) {
            return []
        }
    },
}
