import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/d-jabatan-terakhir', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async store_struktural(_ctx, payload) {
        try {
            let data = await axios.post('/api/d-jabatan-terakhir/store_struktural', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
}
