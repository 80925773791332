import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
// import _ from "lodash";
import qs from "qs";
var user = null
try {
    user = JSON.parse(Cookies.get('user'))
} catch (error) {
    user = null
}
// state
export const state = {
    user: user,
    token: Cookies.get('token'),
    time: Math.floor(Date.now() / 1000)
}
// getters
export const getters = {
    user: state => state.user,
    time: state => state.time,
    token: state => state.token,
    check: state => state.token == null || typeof state.token === 'undefined' ? false : true
}

// mutations
export const mutations = {
    [types.SAVE_TOKEN](state, { data }) {
        state.token = data.token
        Cookies.set('token', state.token)
    },

    [types.FETCH_USER_SUCCESS](state, { data }) {
        state.user = data
        state.time = Math.floor(Date.now() / 1000)
        // if (state.user.role.assignment.length == 0) {
        //     state.user.role = _.cloneDeep(role)
        // }
        Cookies.set('user', state.user)
    },

    [types.FETCH_USER_FAILURE](state) {
        state.user = null
        state.token = null
        state.time = null
        Cookies.remove('token')
    },

    [types.LOGOUT](state) {
        state.user = null
        state.token = null
        state.time = null
        Cookies.remove('user')
        Cookies.remove('token')
    },

    [types.UPDATE_USER](state, { user }) {
        state.user = user
        state.time = Math.floor(Date.now() / 1000)
        // if (state.user.role.assignment.length == 0) {
        //     state.user.role = _.cloneDeep(role)
        // }
        Cookies.set('user', state.user)
    }
}

// actions
export const actions = {
    saveToken({ commit }, payload) {
        commit(types.SAVE_TOKEN, payload)
    },

    async fetchUser({ commit }) {
        try {
            const { data } = await axios.get('/api/user-login')

            commit(types.FETCH_USER_SUCCESS, { data: data })
        } catch (e) {
            commit(types.FETCH_USER_FAILURE)
        }
    },

    updateUser({ commit }, payload) {
        commit(types.UPDATE_USER, payload)
    },

    async logout({ commit }) {
        // await axios.post('/api/user')
        commit(types.LOGOUT)
    },

    async login({ commit }, payload) {
        try {
            const { data, status } = await axios.post('/api/login', qs.stringify(payload))
            commit(types.SAVE_TOKEN, { data: data })
            commit(types.FETCH_USER_SUCCESS, { data: data })
            return status
        } catch (e) {
            commit(types.FETCH_USER_FAILURE)
        }
    },

    async fetchOauthUrl(ctx, { provider }) {
        const { data } = await axios.post(`/api/oauth/${provider}`)

        return data.url
    }
}
