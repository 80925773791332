import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete('/api/mst-unit-kerja/' + payload)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch('/api/mst-unit-kerja/' + payload.id, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/mst-unit-kerja', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get('/api/mst-unit-kerja?page=' + payload.page + '&jabatan=' + payload.data.jabatan + '&nama_unit_kerja=' + payload.data.nama_unit_kerja)
        return data
    }
}
