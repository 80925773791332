import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    async destroy(_ctx, payload) {
        try {
            await axios.delete('/api/pelayanan/' + payload)
        } catch (error) {
            return []
        }
    },
    async update(_ctx, payload) {
        try {
            let data = await axios.patch('/api/pelayanan/' + payload.id, qs.stringify(payload.data))
            return data
        } catch (error) {
            return []
        }
    },
    async verifikasi(_ctx, payload) {
        try {
            let data = await axios.post('/api/pelayanan/verifikasi', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/pelayanan', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async index(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async index_mutasi_masuk(_ctx, payload) {
        const { data } = await axios.get('/api/pelayanan/index_mutasi_masuk?page=' + payload.page + '&nama_layanan=' + payload.data.nama_layanan
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
        )
        return data
    },
    async indexUsulanPenerbitanSkPensiun(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-usulan-penerbitan-sk-pensiun?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexPengesahanPenerbitanSkPensiun(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-pengesahan-penerbitan-sk-pensiun?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexVerifikasiPenerbitanSkPensiunI(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-verifikasi-penerbitan-sk-pensiun-i?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexVerifikasiPenerbitanSkPensiunII(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-verifikasi-penerbitan-sk-pensiun-ii?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexRekapitulasiPenerbitanSkPensiun(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-rekapitulasi-penerbitan-sk-pensiun?page=' + payload.page
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&bulan=' + (payload.data.bulan != null ? payload.data.bulan : '')
            + '&tahun=' + (payload.data.tahun != null ? payload.data.tahun : '')
            + '&golongan=' + (payload.data.golongan != null ? payload.data.golongan : '')
        )
        return data
    },
    async indexUsulanSkumptk(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-usulan-skumptk?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexDisposisiSkumptk(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-disposisi-skumptk?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexVerifikasiSkumptkI(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-verifikasi-skumptk-i?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexVerifikasiSkumptkII(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-verifikasi-skumptk-ii?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async indexPengesahanSkumptk(_ctx, payload) {
        const { data } = await axios.get(
            '/api/pelayanan/index-pengesahan-skumptk?page=' + payload.page + '&jenis_layanan=' + (payload.data.jenis_layanan != null ? payload.data.jenis_layanan : '')
            + '&nama_unit_kerja=' + (payload.data.nama_unit_kerja != null ? payload.data.nama_unit_kerja : '')
            + '&no_surat=' + (payload.data.no_surat != null ? payload.data.no_surat : '')
            + '&jenis_layanan_general=' + (payload.data.jenis_layanan_general != null ? payload.data.jenis_layanan_general : '')
        )
        return data
    },
    async trackingPelayanan(_ctx, payload) {
        try {
            let data = await axios.get('/api/pelayanan/tracking-pelayanan?id=' + payload.id)
            return data
        } catch (error) {
            return []
        }
    },
    async pengesahan(_ctx, payload) {
        try {
            let data = await axios.post('/api/pelayanan/pengesahan', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async verifikasi_i(_ctx, payload) {
        try {
            let data = await axios.get('/api/pelayanan/verifikasi_i?pelayanan_id=' + payload.pelayanan_id + '&status_hasil=' + payload.status_hasil + '&keterangan=' + payload.keterangan)
            return data
        } catch (error) {
            return []
        }
    },
    async verifikasiII(_ctx, payload) {
        try {
            let data = await axios.post('/api/pelayanan/verifikasiII', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },
    async cetakPenerbitanSkPensiun(_ctx, payload) {
        try {
            let data = await axios.get('/api/pelayanan/cetak-penerbitan-sk-pensiun?id=' + payload.id, {responseType: 'blob'})
            return data
        } catch (error) {
            return []
        }
    },
    async cetakRekapitulasiPenerbitanSkPensiun(_ctx, payload) {
        try {
            let data = await axios.get('/api/pelayanan/cetak-rekapitulasi-penerbitan-sk-pensiun?nama_unit_kerja='
            + payload.data.nama_unit_kerja
            + '&bulan=' + payload.data.bulan
            + '&tahun=' + payload.data.tahun
            + '&golongan=' + payload.data.golongan, {responseType: 'blob'})
            return data
        } catch (error) {
            return []
        }
    },
    async download(_ctx, payload) {
        try {
            let data = await axios.get('/api/download?publicPath=' + payload.path, {responseType: 'blob'})
            return data
        } catch (error) {
            return []
        }
    },
}
