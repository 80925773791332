import axios from 'axios'
import qs from "qs";

// actions
export const actions = {
    
    async store(_ctx, payload) {
        try {
            let data = await axios.post('/api/atur-masa-persiapan-pensiun', qs.stringify(payload))
            return data
        } catch (error) {
            return []
        }
    },

    async view(_ctx, payload) {
        const { data } = await axios.get('/api/atur-masa-persiapan-pensiun' + payload.id)
        return data
    },
}