
import axios from 'axios';
// actions
export const actions = {

    async autocomplete(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-unit-kerja/autocomplete?search=' + payload.search + '&id_instansi=' + payload.id_instansi + '&parent=' + payload.parent)
            return data
        } catch (error) {
            return []
        }
    },
    async autocomplete_opd(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-kualifikasi-jabatan-struktural/autocomplete_opd?search=' + payload)
            return data
        } catch (error) {
            return []
        }
    },
    async autocompleteOpdByUser(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/master-kualifikasi-jabatan-struktural/autocomplete-opd-by-user?search=' + payload)
            return data
        } catch (error) {
            return []
        }
    },
}
