
import axios from 'axios';
// actions
export const actions = {
    async r_keluarga(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/d-r-keluarga/r-keluarga?nip=' + payload.nip)
            return data
        } catch (error) {
            return []
        }
    },
}
