

import store from '../store'





function isCanAccessWithSubItem(item) {
    var count = 0;
    if (item) {
        for (let key of Object.keys(item)) {
            for (let key2 of Object.keys(item[key])) {
                if (item[key][key2] == 1) {
                    count++;
                }
            }
        }
    }
    return count > 0 ? true : false;
}

function isCanAccess(item) {
    var count = 0;
    if (item) {
        for (let key of Object.keys(item)) {
            if (item[key] == 1) {
                count++;
            }
        }
    }
    return count > 0 ? true : false;
}

async function checkPermission(route, user) {
    // let user = JSON.parse(_user)
    if (user) {
        if (user.id == 1 || user.id == 7 || user.role.superadmin == true || user.role.superadmin == 1 || user.role.superadmin == null) {
            return true
        } else {
            if (user.role) {
                if (user.role.assignments) {
                    if (route.meta.keyItem != null && route.meta.keySubItem != null && route.meta.keyAction == null) {
                        return isCanAccessWithSubItem(user.role.assignments[route.meta.keyItem][route.meta.keySubItem])
                    } else if (route.meta.keyItem != null && route.meta.keySubItem == null && route.meta.keyAction != null) {
                        return user.role.assignments[route.meta.keyItem][route.meta.keyAction] == 1
                    } else if (route.meta.keyItem != null && route.meta.keySubItem != null && route.meta.keyAction != null) {
                        return user.role.assignments[route.meta.keyItem][route.meta.keySubItem][route.meta.keyAction] == 1
                    } else if (route.meta.keyItem != null && route.meta.keySubItem == null && route.meta.keyAction == null) {
                        return isCanAccess(user.role.assignments[route.meta.keyItem])
                    }
                }
            }
        }
    }
    return false;
}

function scrollBehavior() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("Trident"); // check if the browser is IE

    if (msie > 0) return false;

    var scrollDuration = 600
    var cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();
    function step(newTimestamp) {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step)
}

async function beforeEach(to, from, next) {
    if (to.name == 'Login' && store.getters['auth/check']) {
        next({ name: 'Home' })
    } else if (!store.getters['auth/check'] && to.name != 'Login') {
        next({ name: 'Login' })
    } else if (!store.getters['auth/check'] && to.name == 'Login') {
        next()
    } else if (to.meta.noAuth){
        next();
    } else {
        let user = await store.getters['auth/user'];
        let time = await store.getters['auth/time'];
        if ((time == null) || (user == null) || (time + (60 * 60 * 1000) < (Math.floor(Date.now() / 1000)))) {
            await store.dispatch("auth/fetchUser");
        }
        console.log(user)
        console.log(time)
        let perm = await checkPermission(to, user)
        console.log(perm)
        if (perm) {
            next();
        } else {
            next({ name: 'Page404' })
        }
    }
}

export { scrollBehavior, beforeEach, checkPermission }
