
import axios from 'axios';
import * as types from '../mutation-types'

// state
export const state = {
    options: []
}

// getters
export const getters = {
    options: state => state.options,
}
// mutations
export const mutations = {
    [types.LOAD_OPTION_COMPELTE](state, data) {
        state.options = data
    },
    [types.LOAD_OPTION_FAIL](state) {
        state.options = []
    },
}
// actions
export const actions = {

    async autocomplete({ commit }) {
        try {
            const { data } = await axios.get('/api/master-golongan-ruang/autocomplete')
            commit(types.LOAD_OPTION_COMPELTE, data)
        } catch (error) {
            commit(types.LOAD_OPTION_FAIL)
        }
    },
}
